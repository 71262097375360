import React from "react"
import Layout from "../components/layout"
const ContactPage = () => {
  return (
    <Layout>
      <h1>Contact</h1>
      <p>
        The best way to reach me is via{" "}
        <a href="https://t.me/vasylherman">+380954140885</a> on Telegram!
      </p>
    </Layout>
  )
}
export default ContactPage
